import { Icon } from 'components/Icons';
import { fetchValidateAccesscode } from 'features/channel/channelSlice';
import { useSubdomain } from 'hooks/useSubdomain';
import React, {
  FunctionComponent,
  SyntheticEvent,
  useState,
  useContext
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'rootReducer';
import styles from './styles.module.scss';
import { AccessView } from 'components/Layouts/AccessView';
import { UserAgentContext } from 'features/user-agent/userAgent';
import { FormInline } from 'components/Forms/FormInline';
import { usePageLoaded } from 'hooks/usePageLoaded';
import { useNativeEvents } from 'hooks/useNativeEvents';
import { SEOHeaderTags } from 'components/SEO/SEOHeaderTags';
import { useSEOTitleDescription } from 'hooks/useSEOTitleDescription';

const getContactLink = (linkType: string, link: string): string | null => {
  return link ? (linkType === 'Email' ? `mailto:${link}` : link) : null;
};

export const Privacy: FunctionComponent = () => {
  const [accesscode, setAccesscode] = useState('');
  const dispatch = useDispatch();
  const username = useSubdomain();
  const accesscodeDetails = useSelector(
    (state: RootState) => state.channel.accessCodeDetails
  );
  const { isEmbed } = useContext(UserAgentContext);
  const { postMessage } = useNativeEvents();
  const { pageTitle, pageDescription } = useSEOTitleDescription(
    accesscodeDetails?.username,
    accesscodeDetails?.aboutMe,
    true
  );

  usePageLoaded();

  if (accesscodeDetails == null) return null;

  const contactLink = getContactLink(
    accesscodeDetails.linkType,
    accesscodeDetails.link
  );

  const submitForm = (event: SyntheticEvent) => {
    event.preventDefault();

    if (accesscode.length > 0) {
      dispatch(
        fetchValidateAccesscode(
          username,
          accesscodeDetails.id,
          accesscode,
          postMessage
        )
      );
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAccesscode(event.target.value);
  };

  return (
    <>
      <SEOHeaderTags
        title={pageTitle}
        description={pageDescription}
        image={accesscodeDetails.channelArtwork.image_seo.og_image}
        url={window.location.href}
      />
      <AccessView
        artwork={accesscodeDetails.channelArtwork}
        logo={accesscodeDetails.channelLogo}
        username={username}
      >
        <>
          <h3 className={styles['privacy__header']}>
            <Icon
              hidden={true}
              icon={'lock'}
              label={'Lock'}
              height={18}
              width={18}
            />
            Private channel
          </h3>
          {accesscodeDetails.copy && !isEmbed && (
            <p>{accesscodeDetails.copy}</p>
          )}
          {contactLink && (
            <p>
              <a
                href={contactLink}
                className={styles['privacy__link']}
                target="_blank noopener noreferrer"
              >
                Contact us
              </a>
            </p>
          )}
          <FormInline
            label={'Enter access code to join'}
            inputType={'password'}
            inputName={'private_access_code'}
            buttonlabel={'Join'}
            buttonIcon={'arrowRight'}
            onChange={handleInputChange}
            onSubmit={submitForm}
            formError={accesscodeDetails.failed}
            formErrorMsg={'Access code incorrect'}
          />
        </>
      </AccessView>
    </>
  );
};
