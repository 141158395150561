import { useAudio } from './audio';

export const useAudioMediaSession = (
  title: string | undefined,
  artwork: string | null | undefined,
  type: 'event' | 'recording'
) => {
  const { audio, play, pause, stop } = useAudio();
  const defaultSkipTime = 10;

  if (!title || !audio) return;
  if ('mediaSession' in navigator) {
    navigator.mediaSession.metadata = new MediaMetadata({
      title: title || '',
      artwork: [{ src: artwork || '' }]
    });
    navigator.mediaSession.setActionHandler('play', () => {
      play();
    });
    if (type == 'event') {
      navigator.mediaSession.setActionHandler('stop', () => {
        stop();
      });
    } else {
      navigator.mediaSession.setActionHandler('pause', () => {
        pause();
      });
      navigator.mediaSession.setPositionState({
        duration: audio.duration || 0,
        playbackRate: audio.playbackRate,
        position: audio.currentTime
      });
      navigator.mediaSession.setActionHandler('seekbackward', (details) => {
        const skipTime = details.seekOffset || defaultSkipTime;
        audio.currentTime = Math.max(audio.currentTime - skipTime, 0);
      });
      navigator.mediaSession.setActionHandler('seekforward', (details) => {
        const skipTime = details.seekOffset || defaultSkipTime;
        audio.currentTime = Math.min(
          audio.currentTime + skipTime,
          audio.duration
        );
      });
    }
  }
};
